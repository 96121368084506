// Download.js

import React, { useEffect, useState } from 'react';
import ReactPlayer from 'react-player/youtube';
import { useSearchParams, useNavigate } from 'react-router-dom';
import { ThreeDots } from 'react-loader-spinner';
import '../App.css';
const baseUrl = 'https://api.downloadvids.app/api/';
// const baseUrl = 'http://localhost:8080/api/';

function Download() {
  let [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();
  let url = searchParams.get('v');
  const [expanded, setExpanded] = useState(false);
  const [loading, setLoading] = useState(false);
  const [checkedId, setCheckedId] = useState(undefined);
  const [options, setOptions] = useState([]);
  const [src, setSrc] = useState('');

  async function goto() {
    navigate('/');
  }

  const handler = async () => {
    const apiUrl = checkedId;
    window.open(apiUrl)
  };

  useEffect(() => {
    document.title = `Savevids Video Downloader - Download from Instagram`;
  }, [])

  useEffect(() => {
    if (url) {
      getData()
    }
  }, [url])

  const getData = async () => {
    try {
      setLoading(true)
      let data = await fetch(`${baseUrl}fb-download?v=${encodeURIComponent(url)}`)
      let jsondata = await data.json()
      if(jsondata.status){
        setOptions(jsondata.data)
        setSrc(jsondata.data[0]?.thumbnail)
        setCheckedId(jsondata.data[0]?.url);
      }
      setLoading(false)
    } catch (error) {
      setLoading(false)
      console.log(error)
    }
  };

  const handleDropdownClick = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setExpanded(!expanded);
  };

  function handleOptionClick(e) {
    setCheckedId(e);
    setExpanded(false);
  };

  return (
    <div className={`wrapper ${loading ? 'disable-pointer-events' : ''}`}>
      {loading &&
        <div className="loader-container">
          <ThreeDots height="80" width="80" color="#fff" ariaLabel="bars-loading" visible={loading} />
        </div>
      }
      <header>
        <a href="/" className="logo">
          <img src="/images/logo.png" />
        </a>
      </header>
      <div className="download-page">
        <div className="video-frame">
          <img src={src}/>
          {/* <ReactPlayer url={url} /> */}
        </div>
        <div className="download-video-overlap">
          <button onClick={handler}>Download</button>
          <span className={`dropdown-el ${expanded ? 'expanded' : ''}`} onClick={handleDropdownClick}>
            <div style={{ display: `${(checkedId == undefined || expanded) ? '' : 'none'}` }}>
              <input type="radio" name="sortType" value={undefined} id={undefined} />
              <label htmlFor={undefined}  >{'Select Quality'}</label>
            </div>
            {
              options.map((item, index) => (
                <div key={index} style={{ display: `${(checkedId == item.url || expanded) ? '' : 'none'}` }}>
                  <input type="radio" name="sortType" value={item.url} id={item.url} />
                  <label htmlFor={item.url} onClick={() => handleOptionClick(item.url)}  >{item.resolution ?? "Default"}</label>
                </div>
              ))
            }
          </span>
        </div>
        <div className="download-more" onClick={goto}>
          <button>Download More</button>
        </div>
      </div>
    </div >
  );
}

export default Download;
