import { useState, useEffect } from "react";
import "../App.css";
import { useNavigate } from "react-router-dom";

function App() {
  const [url, setUrl] = useState("");
  const navigate = useNavigate();

  async function downloadHandler() {
    if (url) {
      navigate("/video/download-from-facebook?v=" + url);
    }
  }

  useEffect(() => {
    document.title = `Savevids Video Downloader - Download from Facebook`;
  }, []);

  return (
    <div className="wrapper">
      <header>
        <div className="logo">
          <img src="/images/logo.png" />
        </div>
        <div className="desktop-menu">
          <ul>
            <li>
              <a href="/download-from-youtube">Download Youtube Videos</a>
            </li>
            <li>
              <a href="/download-from-instagram">Download Instagram Videos</a>
            </li>
          </ul>
        </div>

        <div className="mobile-menu">
          <input type="checkbox" id="overlay-input" />
          <label for="overlay-input" id="overlay-button">
            <span></span>
          </label>
          <div id="overlay">
            <ul>
              <li>
                <a href="/download-from-youtube">Download Youtube Videos</a>
              </li>
              <li>
                <a href="/download-from-instagram">Download Instagram Videos</a>
              </li>
            </ul>
          </div>
        </div>
      </header>
      <section className="main-section">
        <div className="search-window">
          <div className="center-content">
            <div className="headings">
              <h1>Free Facebook Video Downloader</h1>
            </div>
            <div className="paste-url-row">
              <div className="url-link-field">
                <div className="url-link-field-box">
                  <input
                    type="text"
                    placeholder="Paste Instagram URL"
                    onChange={(e) => setUrl(e.target.value)}
                    value={url}
                  />
                </div>
                <div className="paste-btn-clipbtn">
                  <button
                    onClick={() => {
                      navigator.clipboard.readText().then((text) => {
                        setUrl(text);
                      });
                    }}
                  >
                    <div>
                      {" "}
                      <img src="/images/paste-icon.png" />{" "}
                    </div>
                    Paste URL
                  </button>
                </div>
              </div>
              <div>
                <button className="download-btn" onClick={downloadHandler}>
                  <div>
                    {" "}
                    <img src="/images/download-icon.png" />{" "}
                  </div>
                  Download
                </button>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="text-content">
        <div className="scanned-by">
          Scanned by <img src="/images/norton.svg" /> NortonTM Safe Web
        </div>

        <section>
          <h2>How It Works:</h2>
          <ul className="numbers-list">
            <li>
              <strong>Copy the URL</strong> of the video you want to download.
            </li>
            <li>
              <strong>Paste the URL</strong> into the designated field on
              DownloadVids.com.
            </li>
            <li>
              Click "<strong>Download</strong>," and let our platform work its
              magic.
            </li>
            <li>
              Once the download is complete, enjoy your video offline, anytime,
              anywhere.
            </li>
          </ul>
        </section>

        <div className="two-col-grid">
          <div>
            Unlock the convenience of downloading your cherished Facebook videos
            for offline pleasure! Whether it's heartwarming memories, humorous
            clips, or inspiring content, Facebook offers a treasure trove of
            videos worth preserving. With DownloadVids.app, effortlessly save
            your favorite videos, including funny moments and engaging posts, in
            the widely favored MP4 format. Say goodbye to limitations and hello
            to seamless entertainment on-the-go!
          </div>

          <div>
            <img src="/images/facebook.png" />
          </div>
        </div>
      </section>
    </div>
  );
}

export default App;
