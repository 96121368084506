import { useState, useEffect } from "react";
import "../App.css";
import { useNavigate } from "react-router-dom";
function getPlatform(url) {
  const platform = url.toLowerCase();
  let type = "";

  switch (true) {
    case platform.includes("instagram"):
      type = "/video/download-from-instagram"; // Instagram
      break;
    case platform.includes("facebook"):
      type = "/video/download-from-facebook"; // Facebook
      break;
    case platform.includes("youtube"):
      type = "/video/download-from-youtube"; // YouTube
      break;
    default:
      type = "/";
  }

  return type;
}

function App() {
  const [url, setUrl] = useState("");
  const navigate = useNavigate();

  async function downloadHandler() {
    if (url) {
      navigate(`${getPlatform(url)}?v=${url}`);
    }
  }

  useEffect(() => {
    document.title = `Savevids Video Downloader - Download from Youtube`;
  }, [])

  return (
    <div className="wrapper">
      <header>
        <div className="logo">
          <img src="/images/logo.png" />
        </div>

        <div className="desktop-menu">
          <ul>
            <li>
              <a href="/download-from-facebook">Download Facebook Videos</a>
            </li>
            <li>
              <a href="/download-from-instagram">Download Instagram Videos</a>
            </li>
          </ul>
        </div>

        <div className="mobile-menu">
          <input type="checkbox" id="overlay-input" />
          <label for="overlay-input" id="overlay-button">
            <span></span>
          </label>
          <div id="overlay">
            <ul>
              <li>
                <a href="/facebook">Download Facebook Videos</a>
              </li>
              <li>
                <a href="/insta">Download Instagram Videos</a>
              </li>
            </ul>
          </div>
        </div>
      </header>

      <section className="main-section">
        <div className="search-window">
          {/* <!--
          <div className="icons-grid">

            <div>
              <img src="images/play-icon.svg" alt=""/>
                <span>Video</span>
            </div>

            <div>
              <img src="images/story-icon.svg" alt=""/>
                <span>Story</span>
            </div>
            <div>
              <img src="images/reel-icon.svg" alt="" />
                <span>Reel</span>
            </div>
            <div>
              <img src="images/igtv.svg" alt=""/>
                <span>IGTV</span>
            </div>

          </div> --> */}

          <div className="center-content">
            <div className="headings">
              <h1>Free Online YouTube Video Downloader</h1>
            </div>

            <div className="paste-url-row">
              <div className="url-link-field">
                <div className="url-link-field-box">
                  <input
                    type="text"
                    value={url}
                    placeholder="Paste Instagram URL"
                    onChange={(e) => setUrl(e.target.value)}
                  />
                </div>
                <div className="paste-btn-clipbtn">
                  <button
                    onClick={() => {
                      navigator.clipboard.readText().then((text) => {
                        setUrl(text);
                      });
                    }}
                  >
                    <div>
                      {" "}
                      <img src="images/paste-icon.png" />{" "}
                    </div>
                    Paste URL
                  </button>
                </div>
              </div>

              <div>
                <button className="download-btn" onClick={downloadHandler}>
                  <div>
                    {" "}
                    <img src="images/download-icon.png" />{" "}
                  </div>
                  Download
                </button>
              </div>
            </div>
          </div>
        </div>
        <section class="text-content">
          <div class="scanned-by">
            Scanned by <img src="/images/norton.svg" /> NortonTM Safe Web
          </div>

          <section>
            <h2>How It Works:</h2>
            <ul class="numbers-list">
              <li>
                <strong>Copy the URL</strong> of the video you want to download.
              </li>
              <li>
                <strong>Paste the URL</strong> into the designated field on
                DownloadVids.com.
              </li>
              <li>
                Click "<strong>Download</strong>," and let our platform work its
                magic.
              </li>
              <li>
                Once the download is complete, enjoy your video offline,
                anytime, anywhere.
              </li>
            </ul>
          </section>

          <div class="two-col-grid">
            <div>
              Explore the increasing trend of downloading YouTube videos to
              enjoy them offline. As YouTube continues to offer a diverse range
              of content, the desire to save and revisit favorite videos offline
              is on the rise. Downloadvids.app presents a user-friendly,
              complimentary online platform for seamlessly downloading YouTube
              videos in the highly favored MP4 format.
            </div>

            <div>
              <img src="/images/yt_logo.png" />
            </div>
          </div>
        </section>
      </section>
    </div>
  );
}

export default App;
