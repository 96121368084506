import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import All from './components/All';
import Youtube from './components/Youtube';
import Facebook from './components/Facebook';
import Download from './components/Download';
import FBDownload from './components/FBDownload';
import IGDownload from './components/IGDownload';
import Insta from './components/Insta';
import reportWebVitals from './reportWebVitals';
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";

const router = createBrowserRouter([
  {
    path: "/",
    element: <All />,
  },
  {
    path: "/download-from-youtube",
    element: <Youtube />,
  },
  {
    path: "/download-from-facebook",
    element: <Facebook />,
  },
  {
    path: "/download-from-instagram",
    element: <Insta />,
  },
  {
    path: "/video/download-from-youtube",
    element: <Download />,
  },
  {
    path: "/video/download-from-facebook",
    element: <FBDownload />,
  },
  {
    path: "/video/download-from-instagram",
    element: <IGDownload />,
  },
]);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
