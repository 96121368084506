import { useState, useEffect } from "react";
import "../App.css";
import { useNavigate } from "react-router-dom";
function getPlatform(url) {
  const platform = url.toLowerCase();
  let type = "";

  switch (true) {
    case platform.includes("instagram"):
      type = "/video/download-from-instagram"; // Instagram
      break;
    case platform.includes("facebook"):
      type = "/video/download-from-facebook"; // Facebook
      break;
    case platform.includes("fb.watch"):
      type = "/video/download-from-facebook"; // Facebook
      break;
    case platform.includes("fb.com"):
      type = "/video/download-from-facebook"; // Facebook
      break;
    case platform.includes("youtube"):
      type = "/video/download-from-youtube"; // YouTube
      break;
    default:
      type = "/";
  }

  return type;
}

function App() {
  const [url, setUrl] = useState("");
  const navigate = useNavigate();

  async function downloadHandler() {
    if (url) {
      navigate(`${getPlatform(url)}?v=${url}`);
    }
  }
  return (
    <div className="wrapper">
      <header>
        <div className="logo">
          <img src="/images/logo.png" />
        </div>

        <div className="desktop-menu">
          <ul>
            <li>
              <a href="/download-from-youtube">Download Youtube Videos</a>
            </li>
            <li>
              <a href="/download-from-facebook">Download Facebook Videos</a>
            </li>
            <li>
              <a href="/download-from-instagram">Download Instagram Videos</a>
            </li>
          </ul>
        </div>

        <div className="mobile-menu">
          <input type="checkbox" id="overlay-input" />
          <label for="overlay-input" id="overlay-button">
            <span></span>
          </label>
          <div id="overlay">
            <ul>
              <li>
                <a href="/download-from-youtube">Download Youtube Videos</a>
              </li>
              <li>
                <a href="/download-from-facebook">Download Facebook Videos</a>
              </li>
              <li>
                {" "}
                <a href="/download-from-instagram">Download Instagram Videos</a>
              </li>
            </ul>
          </div>
        </div>
      </header>

      <section className="main-section">
        <div className="search-window">
          {/* <!--
          <div className="icons-grid">

            <div>
              <img src="images/play-icon.svg" alt=""/>
                <span>Video</span>
            </div>

            <div>
              <img src="images/story-icon.svg" alt=""/>
                <span>Story</span>
            </div>
            <div>
              <img src="images/reel-icon.svg" alt="" />
                <span>Reel</span>
            </div>
            <div>
              <img src="images/igtv.svg" alt=""/>
                <span>IGTV</span>
            </div>

          </div> --> */}

          <div className="center-content">
            <div className="headings">
              <h1>Free Online Video Downloader</h1>
            </div>

            <div className="paste-url-row">
              <div className="url-link-field">
                <div className="url-link-field-box">
                  <input
                    type="text"
                    value={url}
                    placeholder="Paste Instagram URL"
                    onChange={(e) => setUrl(e.target.value)}
                  />
                </div>
                <div className="paste-btn-clipbtn">
                  <button
                    onClick={() => {
                      navigator.clipboard.readText().then((text) => {
                        setUrl(text);
                      });
                    }}
                  >
                    <div>
                      {" "}
                      <img src="images/paste-icon.png" />{" "}
                    </div>
                    Paste URL
                  </button>
                </div>
              </div>

              <div>
                <button className="download-btn" onClick={downloadHandler}>
                  <div>
                    {" "}
                    <img src="images/download-icon.png" />{" "}
                  </div>
                  Download
                </button>
              </div>
            </div>
          </div>
        </div>
        <section className="text-content">
          <div className="scanned-by">
            Scanned by <img src="images/norton.svg" /> NortonTM Safe Web
          </div>

          <section>
            <h2>How It Works:</h2>
            <ul className="numbers-list">
              <li>
                <strong>Copy the URL</strong> of the video you want to download.
              </li>
              <li>
                <strong>Paste the URL</strong> into the designated field on
                DownloadVids.com.
              </li>
              <li>
                Click "<strong>Download</strong>," and let our platform work its
                magic.
              </li>
              <li>
                Once the download is complete, enjoy your video offline,
                anytime, anywhere.
              </li>
            </ul>
          </section>

          <section>
            <h2>Why Choose DownloadVids.com?</h2>
            <ol>
              <li>
                <strong>Easy to Use:</strong> Downloading videos has never been
                simpler. With DownloadVids.com, all you need to do is paste the
                URL of the video you want to download, and voila! Your video
                will be ready to watch offline in no time.
              </li>
              <li>
                <strong>Wide Compatibility:</strong> We support all major video
                platforms, including YouTube, Instagram, Twitter, and Facebook.
                Whether it's a funny cat video, a tutorial, or a motivational
                speech, you can download it all with DownloadVids.com.
              </li>
              <li>
                <strong>High-Quality Downloads:</strong> Don't compromise on
                quality. With DownloadVids.com, you can download videos in the
                highest resolution available, ensuring that your viewing
                experience is top-notch every time.
              </li>
              <li>
                <strong>Fast and Secure:</strong> Our platform is built with
                speed and security in mind. You can trust us to deliver your
                videos quickly and securely, without compromising your data or
                device.
              </li>
              <li>
                <strong>Free of Charge:</strong> Yes, you read that right.
                DownloadVids.com is completely free to use. Say goodbye to
                expensive subscription fees or shady download tools. With us,
                you get high-quality video downloads at zero cost.
              </li>
            </ol>
          </section>
        </section>
      </section>
    </div>
  );
}

export default App;
