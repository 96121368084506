import { useState, useEffect } from "react";
import "../App.css";
import { useNavigate } from "react-router-dom";

function App() {
  const [url, setUrl] = useState("");
  const navigate = useNavigate();

  async function downloadHandler() {
    if (url) {
      navigate("/video/download-from-instagram?v=" + url);
    }
  }

  useEffect(() => {
    document.title = `Savevids Video Downloader - Download from Instagram`;
  }, []);

  return (
    <div className="wrapper">
      <header>
        <div className="logo">
          <img src="/images/logo.png" />
        </div>
        <div className="desktop-menu">
          <ul>
            <li>
              <a href="/download-from-youtube">Download Youtube Videos</a>
            </li>
            <li>
              <a href="/download-from-facebook">Download Facebook Videos</a>
            </li>
          </ul>
        </div>

        <div className="mobile-menu">
          <input type="checkbox" id="overlay-input" />
          <label for="overlay-input" id="overlay-button">
            <span></span>
          </label>
          <div id="overlay">
            <ul>
              <li>
                <a href="/download-from-youtube">Download Youtube Videos</a>
              </li>
              <li>
                <a href="/download-from-facebook">Download Facebook Videos</a>
              </li>
            </ul>
          </div>
        </div>
      </header>
      <section className="main-section">
        <div className="search-window">
          <div className="center-content">
            <div className="headings">
              <h1>Free Instagram Video Downloader</h1>
            </div>
            <div className="paste-url-row">
              <div className="url-link-field">
                <div className="url-link-field-box">
                  <input
                    type="text"
                    placeholder="Paste Instagram URL"
                    onChange={(e) => setUrl(e.target.value)}
                    value={url}
                  />
                </div>
                <div className="paste-btn-clipbtn">
                  <button
                    onClick={() => {
                      navigator.clipboard.readText().then((text) => {
                        setUrl(text);
                      });
                    }}
                  >
                    <div>
                      {" "}
                      <img src="/images/paste-icon.png" />{" "}
                    </div>
                    Paste URL
                  </button>
                </div>
              </div>
              <div>
                <button className="download-btn" onClick={downloadHandler}>
                  <div>
                    {" "}
                    <img src="/images/download-icon.png" />{" "}
                  </div>
                  Download
                </button>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section class="text-content">
        <div class="scanned-by">
          Scanned by <img src="/images/norton.svg" /> NortonTM Safe Web
        </div>

        <section>
          <h2>How It Works:</h2>
          <ul class="numbers-list">
            <li>
              <strong>Copy the URL</strong> of the video you want to download.
            </li>
            <li>
              <strong>Paste the URL</strong> into the designated field on
              DownloadVids.com.
            </li>
            <li>
              Click "<strong>Download</strong>," and let our platform work its
              magic.
            </li>
            <li>
              Once the download is complete, enjoy your video offline, anytime,
              anywhere.
            </li>
          </ul>
        </section>

        <div class="two-col-grid">
          <div>
            Explore the growing craze of downloading your favorite videos from
            Instagram for offline enjoyment! From captivating stories to amusing
            reels and heartwarming posts, Instagram is a treasure trove of
            diverse content waiting to be saved. With DownloadVids.app,
            effortlessly download and keep your cherished Instagram videos,
            including funny clips and entertaining memes, in the highly favored
            MP4 format. Say goodbye to limitations and relish your beloved
            videos whenever and wherever you want.
          </div>

          <div>
            <img src="/images/ig-logo.png" />
          </div>
        </div>
      </section>
    </div>
  );
}

export default App;
